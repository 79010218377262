<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="failed_jobs"
                          columnsPrefix="jobs.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="jobs.title.failed_jobs"
                >
                    <template #cell(status)="row">
                        <CBadge color="warning">{{ row.item.status }}</CBadge>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'FailedJobs',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Jobs', ['getFailedJobsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Jobs/fetchFailedJobs', 'getFailedJobsTable');
        },
    },
}
</script>